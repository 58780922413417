import { Content } from './styled';

export type HomePageDisclaimerType = {
  text: string;
  start_date: Date;
  end_date: Date;
};

type HomePageDisclaimerProps = {
  data: HomePageDisclaimerType;
};

export function HomePageDisclaimer({ data }: HomePageDisclaimerProps) {
  const { text, start_date, end_date } = data;

  if (!text || !start_date || !end_date) {
    return null;
  }

  const currentDate = new Date();
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  if (currentDate < startDate || currentDate > endDate) {
    return null;
  }

  return <Content dangerouslySetInnerHTML={{ __html: text }} />;
}
