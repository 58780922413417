import {
  bffService,
  ecomService,
  config,
  DistanceUnit,
} from '@thisisdevelopment/akzonobel-core';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-KTGQRMH',
};

export const init = () => {
  const ecomURL = process.env.NEXT_PUBLIC_ECOM_URL;

  config.googleApiKey = `${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`;
  config.bugsnagApiKey = `${process.env.NEXT_PUBLIC_BUGSNAG_API_KEY}`;
  config.sprinklrEnabledHome = false;
  config.sprinklrEnabledColor = true;
  config.showCookieSettings = true;
  config.sprinklrClientId = '5632';
  config.sprinklrEmbedId = 'ZW1iZWQ6NWY5MDMxMTFjYmU5OTMzMWIxOTcxYjYw';
  config.domainCode = `${process.env.NEXT_PUBLIC_DOMAIN_CODE}`;
  config.channel = `${process.env.NEXT_PUBLIC_CHANNEL}`;
  config.language = `${process.env.NEXT_PUBLIC_LANGUAGE}`;
  config.distanceUnit = DistanceUnit.Imperial;
  config.cartUrl = `${ecomURL}/cart`;
  config.ecomEnabled = true;
  config.hasSubBar = true;
  config.subBarHeight = 40;
  config.socials = {
    facebook: 'https://www.facebook.com/duluxheritage',
    instagram: 'https://www.instagram.com/duluxheritage',
    pinterest: 'https://www.pinterest.com/duluxheritage',
  };
  config.relatedSites = [
    {
      url: 'https://www.dulux.co.uk',
      title: 'Dulux',
    },
    {
      url: 'https://www.cuprinol.co.uk',
      title: 'Cuprinol',
    },
    {
      url: 'https://www.duluxselectdecorators.co.uk',
      title: 'Dulux Select Decorators',
    },
    {
      url: 'https://www.hammerite.nl',
      title: 'Hammerite',
    },
    {
      url: 'https://www.polycell.co.uk',
      title: 'Polycell',
    },
  ];
  config.menuItems = [
    { label: 'The Heritage Story', to: '/en/the-heritage-story' },
    {
      label: 'Find your paint',
      items: [
        { label: 'Our colours', to: '/en/colours' },
        { label: 'Our products', to: '/en/products' },
      ],
    },
    {
      label: 'Inspiration and Advice',
      items: [
        { label: 'Ideas', to: '/en/inspiration/category/ideas' },
        {
          label: 'Practical help',
          to: '/en/inspiration/category/practical-help',
        },
        {
          label: 'At Home with Heritage',
          to: '/en/inspiration/category/at-home-with-heritage',
        },
      ],
    },
    { label: 'For Professionals', to: '/en/for-professionals' },
    {
      label: 'Find a Stockist',
      to: '/en/stores',
      onlyMobileVisible: true,
    },
    {
      label: 'Order your free colour card',
      to: '/en/products/details/dulux-heritage-colour-card',
      onlyMobileVisible: true,
    },
  ];
  config.enableChangeLights = true;
  bffService.initAxiosConfig(`${process.env.NEXT_PUBLIC_BFF_URL}`);
  ecomService.initAxiosInstance(`${ecomURL}/api`);
  if (typeof window !== 'undefined') {
    TagManager.initialize(tagManagerArgs);
  }
};

export const pageRevalidate = parseInt(process.env.PAGE_REVALIDATE ?? '86400');

export const tryDifferentLightsId = 'try-different-lights';
