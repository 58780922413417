import React from 'react';
import type { CustomIconProps } from '../CustomIcon';

export const Moon = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M17.9682 11.1583C17.8371 12.5768 17.3047 13.9287 16.4334 15.0557C15.562 16.1826 14.3878 17.0382 13.0479 17.5221C11.7081 18.0061 10.2582 18.0984 8.86776 17.7884C7.47736 17.4784 6.20401 16.7788 5.1967 15.7715C4.18939 14.7642 3.48979 13.4908 3.17977 12.1004C2.86974 10.71 2.96211 9.26007 3.44606 7.92025C3.93002 6.58042 4.78554 5.40614 5.91252 4.53479C7.03951 3.66345 8.39134 3.13109 9.80985 3C8.97936 4.12356 8.57972 5.50788 8.68362 6.90118C8.78752 8.29448 9.38806 9.60422 10.376 10.5922C11.364 11.5801 12.6737 12.1807 14.067 12.2846C15.4603 12.3885 16.8446 11.9888 17.9682 11.1583Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
