import { createContext, useState, useCallback, useContext } from 'react';

export const TIME = {
  DAY: 'day',
  NIGHT: 'night',
} as const;

export const LIGHT_TYPES = {
  DAY: {
    SUNRISE: 'Sunrise',
    SUNSET: 'Sunset',
    DAYTIME: 'Daytime',
  },
  NIGHT: {
    HANGING: 'Hanging Lights',
    SPOT: 'Spot Lights',
  },
} as const;

export const LIGHT_SUBTYPES = {
  DIRECTIONS: {
    EAST: 'East',
    NORTH: 'North',
    WEST: 'West',
    SOUTH: 'South',
  },
  TEMPERATURES: {
    WARM: 'Warm',
    COLD: 'Cold',
  },
} as const;

export type Time = typeof TIME[keyof typeof TIME];
export type LightType =
  | typeof LIGHT_TYPES.DAY[keyof typeof LIGHT_TYPES.DAY]
  | typeof LIGHT_TYPES.NIGHT[keyof typeof LIGHT_TYPES.NIGHT];
export type LightSubType =
  | typeof LIGHT_SUBTYPES.DIRECTIONS[keyof typeof LIGHT_SUBTYPES.DIRECTIONS]
  | typeof LIGHT_SUBTYPES.TEMPERATURES[keyof typeof LIGHT_SUBTYPES.TEMPERATURES];

export type LightsState = {
  time: Time;
  lightType: LightType;
  lightSubType: LightSubType;
};

export interface ILightsContext extends LightsState {
  setLightsTime: (time: Time) => void;
  setLightType: (lightType: LightType) => void;
  setLightSubType: (lightSubType: LightSubType) => void;
}

const initialState: LightsState = {
  time: TIME.DAY,
  lightType: LIGHT_TYPES.DAY.SUNRISE,
  lightSubType: LIGHT_SUBTYPES.DIRECTIONS.EAST,
};

const getDefaultsForTime = (
  time: Time
): Pick<LightsState, 'lightType' | 'lightSubType'> => ({
  lightType:
    time === TIME.DAY ? LIGHT_TYPES.DAY.SUNRISE : LIGHT_TYPES.NIGHT.HANGING,
  lightSubType:
    time === TIME.DAY
      ? LIGHT_SUBTYPES.DIRECTIONS.EAST
      : LIGHT_SUBTYPES.TEMPERATURES.WARM,
});

const LightsContext = createContext<ILightsContext | undefined>(undefined);

interface IProviderProps {
  children: React.ReactNode;
}

export function LightsProvider({ children }: IProviderProps) {
  const [state, setState] = useState<LightsState>(initialState);

  const stateUpdater = useCallback((updates: Partial<LightsState>) => {
    setState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  }, []);

  const contextValue: ILightsContext = {
    ...state,
    setLightsTime: useCallback(
      (time: Time) => {
        stateUpdater({
          time,
          ...getDefaultsForTime(time),
        });
      },
      [stateUpdater]
    ),
    setLightType: useCallback(
      (lightType: LightType) => {
        stateUpdater({ lightType });
      },
      [stateUpdater]
    ),
    setLightSubType: useCallback(
      (lightSubType: LightSubType) => {
        stateUpdater({ lightSubType });
      },
      [stateUpdater]
    ),
  };

  return (
    <LightsContext.Provider value={contextValue}>
      {children}
    </LightsContext.Provider>
  );
}

export function useLightsContext() {
  const context = useContext(LightsContext);

  if (context === undefined) {
    throw new Error('useLightsContext must be used within a LightsProvider');
  }

  return context;
}
