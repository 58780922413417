import { useMemo } from 'react';
import {
  useLightsContext,
  LightType,
  LightSubType,
} from '@/contexts/lightContext';
import { useImageStatus } from './useImageStatus';

const DAY_DIRECTION_MAP = {
  North: 'NORTH',
  East: 'EAST',
  West: 'EAST', // Intentionally using 'EAST' for West, because there currently is no difference.
  South: 'SOUTH',
} as const;

const DAY_TIME_MAP = {
  Sunrise: '-7H',
  Daytime: '-12H',
  Sunset: '-19H',
} as const;

const NIGHT_LIGHT_MAP = {
  'Hanging Lights': 'PENDANT',
  'Spot Lights': 'CEILING',
} as const;

const NIGHT_TEMP_MAP = {
  Warm: '-WARM',
  Cold: '-COLD',
} as const;

const isDayDirection = (
  value: LightSubType
): value is keyof typeof DAY_DIRECTION_MAP => {
  return value in DAY_DIRECTION_MAP;
};

const isDayTime = (value: LightType): value is keyof typeof DAY_TIME_MAP => {
  return value in DAY_TIME_MAP;
};

const isNightLight = (
  value: LightType
): value is keyof typeof NIGHT_LIGHT_MAP => {
  return value in NIGHT_LIGHT_MAP;
};

const isNightTemp = (
  value: LightSubType
): value is keyof typeof NIGHT_TEMP_MAP => {
  return value in NIGHT_TEMP_MAP;
};

export function useDifferentLightsImage(cccid: number) {
  const { time, lightType, lightSubType } = useLightsContext();

  const imageSrc = useMemo(() => {
    const baseUrl = 'https://msp.images.akzonobel.com/glb/uk/eukher/rooms';
    const baseName = `${cccid}-`;

    if (time === 'day') {
      if (!isDayDirection(lightSubType) || !isDayTime(lightType)) {
        console.warn('Invalid day configuration:', { lightType, lightSubType });
        return null;
      }

      const direction = DAY_DIRECTION_MAP[lightSubType];
      const timeOfDay = DAY_TIME_MAP[lightType];
      return `${baseUrl}/${baseName}${direction}${timeOfDay}.jpg`;
    }

    if (!isNightLight(lightType) || !isNightTemp(lightSubType)) {
      console.warn('Invalid night configuration:', { lightType, lightSubType });
      return null;
    }

    const lightStyle = NIGHT_LIGHT_MAP[lightType];
    const temperature = NIGHT_TEMP_MAP[lightSubType];
    return `${baseUrl}/${baseName}${lightStyle}${temperature}.jpg`;
  }, [cccid, time, lightType, lightSubType]);

  const status = useImageStatus(imageSrc);

  return { imageSrc, status };
}
