import type { CustomIconProps } from '../CustomIcon';

export function Sun(props: CustomIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="sun 1" clipPath="url(#clip0_33_385)">
        <path
          id="Vector"
          d="M10.0002 14.1666C12.3013 14.1666 14.1668 12.3011 14.1668 9.99992C14.1668 7.69873 12.3013 5.83325 10.0002 5.83325C7.69898 5.83325 5.8335 7.69873 5.8335 9.99992C5.83349 12.3011 7.69897 14.1666 10.0002 14.1666Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M10 0.833252L10 2.49992"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M10 17.5L10 19.1667"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M3.5166 3.5166L4.69993 4.69994"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M15.2998 15.3L16.4831 16.4834"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_6"
          d="M0.833496 10L2.50016 10"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_7"
          d="M17.5 10L19.1667 10"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_8"
          d="M3.5166 16.4834L4.69993 15.3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_9"
          d="M15.2998 4.69993L16.4831 3.5166"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_33_385">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
