import styled from 'styled-components';
import { ContainerInner } from 'elements';
import theme from 'theme';

export const Content = styled(ContainerInner)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 40px 0;
  background-color: ${theme.colors.white};

  h3 {
    text-transform: uppercase;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.brandGold};
  }

  p {
    color: ${({ theme }) => theme.colors.black};
  }

  @media ${theme.mediaQuery.tablet} {
    h3 {
      font-size: 1.2rem;
    }

    p {
      font-size: 1rem;
    }
  }

  @media ${theme.mediaQuery.desktop} {
    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1.1rem;
    }
  }
`;
