import type { CustomIconProps } from '../CustomIcon';

export function Sunset(props: CustomIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_33_25)">
        <path
          d="M14.1666 14.9999C14.1666 13.8948 13.7276 12.835 12.9462 12.0536C12.1648 11.2722 11.105 10.8333 9.99992 10.8333C8.89485 10.8333 7.83504 11.2722 7.05364 12.0536C6.27224 12.835 5.83325 13.8948 5.83325 14.9999"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 7.50008V1.66675"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5166 8.5166L4.69993 9.69993"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.833252 15H2.49992"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 15H19.1667"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3 9.69993L16.4834 8.5166"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1666 18.3333H0.833252"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3334 4.16675L10.0001 7.50008L6.66675 4.16675"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_33_25">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
