import styled, { css } from 'styled-components';
import {
  shouldForwardProp,
  ButtonElement,
} from '@thisisdevelopment/akzonobel-core';
import { ContainerInner } from 'elements';
import { mediaQuery } from 'theme';

export const Content = styled.div`
  position: relative;

  @media ${mediaQuery.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

export const Background = styled.div`
  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 680px;
  }

  @media ${mediaQuery.tablet} {
    img {
      height: unset;
    }
  }
`;

const Description = styled.section`
  padding: 30px calc(160px + 400px + 30px) 30px 160px;

  @media (max-width: 1640px) {
    padding: 30px calc(20px + 400px + 30px) 30px 20px;
  }

  p {
    margin: 0;
    font-size: 1.3rem;
  }

  @media ${mediaQuery.tablet} {
    padding: 20px;

    p {
      font-family: 'Source Sans Pro', sans-serif;
      margin: 5px 0 0 0;
      letter-spacing: 0.05em;
    }
  }
`;

export const ProductDescription = styled(Description)`
  background-color: ${({ theme }) => theme.colors.brandBlue};
  color: ${({ theme }) => theme.colors.white};

  h2 {
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
  }

  @media ${mediaQuery.tablet} {
    h2 {
      color: ${({ theme }) => theme.colors.brandGold};
      text-transform: unset;
    }
  }
`;

export const ColorDescription = styled(Description).withConfig<{
  textColor: string;
}>({
  shouldForwardProp,
})`
  color: ${({ textColor }) => textColor};
`;

export const Header = styled(ContainerInner)`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  h1 {
    font-size: 1.7rem;
    font-weight: 400;

    strong {
      font-size: 2rem;
      font-weight: 600;
    }
  }

  h2 {
    font-size: 1.7rem;
    font-weight: 600;
    margin-right: 15px;
  }

  @media ${mediaQuery.tablet} {
    justify-content: center;
    padding: 30px 0;

    > :first-child {
      display: none;
    }

    h1 {
      span {
        display: none;
      }
    }
  }
`;

export const ArticleContentWrapper = styled.div`
  opacity: 0;
  transition: all ease 0.4s;
`;

export const ArticleWrapper = styled.section<{ collapsed: boolean }>`
  background-color: white;

  @media ${mediaQuery.desktop} {
    position: absolute;
    top: 640px;
    right: 160px;
    height: 40px;
    width: 400px;
    z-index: 2;
    border-radius: 6px 6px 0 0;
    filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.2));
    overflow: hidden;
    transition: all ease 0.4s;
  }

  @media (max-width: 1640px) {
    right: 20px;
  }

  @media ${mediaQuery.tablet} {
    width: 100%;

    ${ArticleContentWrapper} {
      opacity: 1;
    }
  }

  ${({ collapsed }) =>
    !collapsed &&
    css`
      @media ${mediaQuery.desktop} {
        top: 25px;
        height: calc(100% - 50px);
      }

      ${ArticleContentWrapper} {
        opacity: 1;
      }
    `}
`;

export const ArticleHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px 6px 0 0;
  background-color: ${({ theme }) => theme.colors.brandGold};
  color: ${({ theme }) => theme.colors.white};
  padding: 0 30px;
  height: 40px;
  cursor: pointer;

  h2 {
    font-size: 1.1rem;
    font-weight: 600;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
    width: 30px;
    height: 30px;
  }

  @media ${mediaQuery.tablet} {
    border-radius: unset;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.brandGray450};
    height: 60px;

    svg {
      display: none;
    }
  }
`;

export const ChooseButton = styled(ButtonElement)`
  background-color: ${({ theme }) => theme.colors.brandGold};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  height: 35px;
  width: 35px;
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
`;

export const ColorPickerWrapper = styled.div`
  opacity: 0;
  transition: all ease 0.4s;
  transition-delay: 0.4s;
`;

export const ColorsWrapper = styled.section<{ collapsed: boolean }>`
  position: absolute;
  top: 0;
  width: 0;
  height: 500px;
  background-color: white;
  z-index: 2;
  transition: all ease 0.4s;
  filter: drop-shadow(2px 0 2px rgba(0, 0, 0, 0.2));

  @media ${mediaQuery.tablet} {
    height: 100vh;
  }

  ${({ collapsed }) =>
    !collapsed &&
    css`
      width: calc(100% - 700px);

      @media (max-width: 1640px) {
        width: calc(100% - 500px);
        height: 650px;
      }

      @media ${mediaQuery.tablet} {
        width: calc(100% - 30px);
        height: auto;
      }

      ${ColorPickerWrapper} {
        opacity: 1;
      }
    `}
`;

export const ColorsHeader = styled.header`
  position: absolute;
  top: 100px;
  right: -40px;
  width: 40px;
  height: 180px;
  background-color: ${({ theme }) => theme.colors.brandGold};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
  overflow: hidden;

  h2 {
    text-transform: uppercase;
    transform: rotate(270deg);
    font-size: 1.1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};
    margin-top: 40px;
  }

  h3 {
    font-size: 2.4rem;
    color: ${({ theme }) => theme.colors.white};
  }

  h4 {
    font-size: 2.4rem;
    transform: rotate(45deg);
    transition: transform 1000ms ease-in-out;
    color: ${({ theme }) => theme.colors.white};
  }

  @media ${mediaQuery.tablet} {
    top: 50px;
    right: -30px;
    width: 30px;
  }
`;

export const TryDifferentLightsCTA = styled.button`
  background-color: ${({ theme }) => theme.colors.brandGold};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 0 0 6px 6px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 1rem;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 0.5rem;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  overflow: hidden;
  transition: all ease 0.4s;

  @media ${mediaQuery.mobile} {
    position: relative;
    margin: 0 auto;
    right: 0;
    left: 0;
    border-radius: 6px 6px 0 0;
    font-size: 1rem;
    font-weight: 700;
  }

  & svg {
    transition: all ease 0.2s;
    fill: ${({ theme }) => theme.colors.white};
    width: 25px;
    height: 25px;

    @media ${mediaQuery.mobile} {
      width: 20px;
      height: 20px;
    }
  }

  &:hover:not(:disabled) {
    border: none;
  }

  &:active {
    border: none;
  }
`;
