import { GetStaticProps } from 'next';
import { useTheme } from 'styled-components';
import { bffService, config, Wrapper } from '@thisisdevelopment/akzonobel-core';
import {
  CmsBanner,
  CmsCallToActions,
  CmsCallToActionType,
  CmsSections,
  CmsSectionType,
  FooterBlocks,
  Sprinklr,
} from '@thisisdevelopment/heritage-core-library';
import NextHead from '@/components/NextHead';
import metas from '@/routes/meta';
import { pageRevalidate } from '../../src/config';
import { type CountDownSettings, CountDownTimer } from '@/components/Countdown';
import {
  type HomePageDisclaimerType,
  HomePageDisclaimer,
} from 'components/HomePageDisclaimer';

type HomePageType = {
  banner: number;
  banner_title: string;
  banner_subtitle: string;
  banner_headline: string;
  button_link: string;
  button_label: string;
  sections: CmsSectionType[];
  call_to_actions_title: string;
  call_to_actions: CmsCallToActionType[];
};

type SSRPageProps = {
  page: HomePageType;
  countdownSettings: CountDownSettings;
  disclaimerData: HomePageDisclaimerType;
};

function HomePage({ page, countdownSettings, disclaimerData }: SSRPageProps) {
  const theme = useTheme();

  const meta = metas.default;

  return (
    <div>
      <NextHead meta={meta} />
      <Wrapper as="main" bgColor={theme.colors.white}>
        {page && (
          <>
            <CmsBanner
              imageId={page.banner}
              title={page.banner_title}
              subtitle={page.banner_subtitle}
              headline={page.banner_headline}
              buttonLink={page.button_link}
              buttonLabel={page.button_label}
            />
            <CountDownTimer settings={countdownSettings} />
            <HomePageDisclaimer data={disclaimerData} />
            <CmsSections sections={page.sections} />
            <CmsCallToActions
              title={page.call_to_actions_title}
              items={page.call_to_actions}
              mb="40px"
            />
            <FooterBlocks page="homepage" mb="40px" />
            {config.sprinklrEnabledHome && <Sprinklr />}
          </>
        )}
      </Wrapper>
    </div>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  const page = await bffService
    .getCmsItem('homepage')
    .then((res) => res.data.data[0]);
  const countdownSettings = await bffService
    .getCmsItem('countdown')
    .then((res) => res.data.data[0]);
  const disclaimerData = await bffService
    .getCmsItem('home_page_disclaimer')
    .then((res) => res.data.data[0]);
  return {
    props: { page, countdownSettings, disclaimerData },
    revalidate: pageRevalidate,
  };
};

export default HomePage;
