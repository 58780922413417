import React from 'react';
import type { CustomIconProps } from '../CustomIcon';

export const Group = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      d="M18 9C18 9.49499 17.595 9.9 17.1 9.9H14.94C15.4917 10.4434 16.731 11.1355 15.966 12.033C15.6392 12.4027 15.0582 12.4339 14.697 12.096L12.258 9.9H9.9V12.258L12.096 14.697C12.429 15.066 12.402 15.633 12.033 15.966C11.6719 16.3038 11.0908 16.2728 10.764 15.903L9.9 14.94V17.1C9.9 17.595 9.49499 18 9 18C8.50501 18 8.1 17.595 8.1 17.1V14.94L7.23598 15.903C6.44522 16.771 5.11799 15.5718 5.90404 14.6969L8.1 12.258V9.9H5.742L3.30298 12.096C2.94185 12.4339 2.36079 12.4027 2.03401 12.033C1.70101 11.664 1.72798 11.097 2.09702 10.764L3.06002 9.9H0.9C0.405008 9.9 0 9.49499 0 9C0 8.50501 0.405008 8.1 0.9 8.1H3.06002L2.09702 7.23598C1.23112 6.44605 2.42254 5.11721 3.30302 5.90404L5.742 8.1H8.1V5.742L5.904 3.30298C5.11821 2.4283 6.4452 1.22878 7.23601 2.097L8.1 3.05997V0.9C8.1 0.405011 8.50501 0 9 0C9.49499 0 9.9 0.405011 9.9 0.9V3.05997L10.764 2.09696C11.5547 1.22903 12.882 2.42821 12.096 3.30302L9.9 5.742V8.1H12.258L14.697 5.904C15.5717 5.11821 16.7713 6.44522 15.9029 7.23602L14.94 8.1H17.1C17.595 8.1 18 8.50501 18 9Z"
      fill="#C0AD86"
    />
  </svg>
);
