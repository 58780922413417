import { useState, useEffect } from 'react';

export function useImageStatus(imageUrl: string | undefined | null) {
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>(
    'loading'
  );

  useEffect(() => {
    if (!imageUrl) {
      setStatus('error');
      return;
    }
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => setStatus('success');
    img.onerror = () => setStatus('error');
  }, [imageUrl]);

  return status;
}
