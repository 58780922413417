import { DistanceUnit } from '../types';

type RelatedSiteType = {
  url: string;
  title: string;
};

type MenuItem =
  | {
      label: string;
      to: string;
      items?: undefined;
      onlyMobileVisible?: boolean;
    }
  | {
      label: string;
      items: {
        label: string;
        to: string;
      }[];
      to?: undefined;
    };

type Config = {
  distanceUnit: DistanceUnit;
  googleApiKey: string;
  bugsnagApiKey: string;
  sprinklrEnabled: boolean;
  sprinklrEnabledHome: boolean;
  sprinklrEnabledColor: boolean;
  sprinklrClientId: string;
  sprinklrEmbedId: string;
  showCookieSettings: boolean;
  socials?: {
    facebook?: string;
    instagram?: string;
    pinterest?: string;
  };
  menuItems: MenuItem[];
  relatedSites?: RelatedSiteType[];
  domainCode: string;
  channel: string;
  translations?: any;
  language?: string;
  cartUrl?: string;
  ecomEnabled: boolean;
  hasSubBar: boolean;
  subBarHeight: number;
  enableChangeLights: boolean;
};

const config: Config = {
  distanceUnit: DistanceUnit.Metric,
  googleApiKey: '',
  bugsnagApiKey: '',
  sprinklrEnabled: false,
  sprinklrEnabledHome: false,
  sprinklrEnabledColor: false,
  showCookieSettings: false,
  sprinklrClientId: '',
  sprinklrEmbedId: '',
  menuItems: [],
  domainCode: '',
  channel: '',
  ecomEnabled: false,
  hasSubBar: false,
  subBarHeight: 0,
  enableChangeLights: true,
};

export default config;
