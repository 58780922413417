import React from 'react';
import {
  ContainerFull,
  Footer,
  Topbar,
} from '@thisisdevelopment/heritage-core-library';
import styled, { ThemeProvider } from 'styled-components';
import {
  EcomContextProvider,
  ErrorBoundary,
  NotificationsContextProvider,
} from '@thisisdevelopment/akzonobel-core';
import theme from '@/theme';
import GlobalStyle from '@/theme/GlobalStyle';
import { init } from '@/config';
import { AppProps } from 'next/app';
import { LightsProvider } from '@/contexts/lightContext';

init();

const AppStyled = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
`;

function App({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <NotificationsContextProvider>
          <EcomContextProvider>
            <LightsProvider>
              <Topbar />
              <ContainerFull>
                <AppStyled>
                  <Component {...pageProps} />
                  <Footer />
                </AppStyled>
              </ContainerFull>
            </LightsProvider>
          </EcomContextProvider>
        </NotificationsContextProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
