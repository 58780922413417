import React, { FC, useState } from 'react';
import { Color, H2 } from '@thisisdevelopment/akzonobel-core';
import ColorCard from 'components/ColorCard';
import {
  ColorPickerStyled,
  ColorsContainer,
  Group,
  GroupButton,
  Groups,
  SelectedGroup,
} from './styled';

type PropType = {
  colorsGrouped: { [key: string]: Color[] };
  mode: 'Product' | 'Color';
  collapsed: boolean;
};

const ColorPicker: FC<PropType> = ({
  colorsGrouped,
  mode,
  collapsed,
}: PropType) => {
  const [selectedGroup, setSelectedGroup] = useState<string>(
    Object.keys(colorsGrouped)[0]
  );

  return (
    <ColorPickerStyled collapsed={collapsed}>
      <Groups>
        {Object.keys(colorsGrouped).map((group) => (
          <Group key={`ColorGroup_${group}`}>
            <GroupButton
              isActive={selectedGroup === group}
              onClick={() => setSelectedGroup(group)}
            >
              {group}
            </GroupButton>
          </Group>
        ))}
      </Groups>
      <SelectedGroup>
        <H2>{selectedGroup}</H2>
        {Object.keys(colorsGrouped).map((group) => (
          <ColorsContainer key={group} selected={selectedGroup === group}>
            {colorsGrouped[group].map((color) => (
              <ColorCard
                mode={mode}
                key={`Color_${color.colorCollectionColorId}`}
                color={color}
              />
            ))}
          </ColorsContainer>
        ))}
      </SelectedGroup>
    </ColorPickerStyled>
  );
};

export default ColorPicker;
